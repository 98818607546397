import styled from "@emotion/styled";

const Blog = () => {
  return <Container></Container>;
};

export default Blog;

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: box;
`;
