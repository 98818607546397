/* eslint-disable react-hooks/exhaustive-deps */
import styled from "@emotion/styled";

import { useEffect, useState, useRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";

const ComparisonDisplay = ({
  datafile1,
  datafile2,
  maxHeight = "400px",
  showLabel = true,
}) => {
  const possibleModels = Object.keys(datafile1?.options || []);

  const [currentModel, setCurrentModel] = useState(possibleModels?.[0] || "");
  console.log(currentModel);

  return (
    <OuterContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          maxHeight,
          overflowY: "hidden",
        }}
      >
        <PrompCompletionArea
          maxHeight={maxHeight}
          prompt={datafile1?.prompt}
          completion={datafile1?.options?.[currentModel]}
          key={"firstArea"}
          label={showLabel && datafile1?.label}
        />
        <PrompCompletionArea
          maxHeight={maxHeight}
          prompt={datafile2?.prompt}
          completion={datafile2?.options?.[currentModel]}
          key={"secondArea"}
          label={showLabel && datafile2?.label}
        />
      </div>

      <Dropdown>
        <div
          style={{ display: "flex", flexDirection: "column", width: "200px" }}
        >
          <div>
            <Dropdown.Toggle variant="success">{currentModel}</Dropdown.Toggle>
          </div>
        </div>
        <Dropdown.Menu>
          {possibleModels?.map((model) => (
            <Dropdown.Item onClick={() => setCurrentModel(model)} key={model}>
              {model}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </OuterContainer>
  );
};

export default ComparisonDisplay;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PrompCompletionArea = ({ prompt, completion, maxHeight, label }) => {
  const parentRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      parentRef.current.scrollTop = parentRef.current.scrollHeight;
    }, 0);
  }, [completion]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {label && <div>{label}</div>}
      <div
        style={{
          position: "relative",
          display: "inline-block",
          padding: "15px",
          border: "1px solid black",
          width: "325px",
          maxHeight,
          overflowY: "auto",
          margin: "5px",
        }}
        ref={parentRef}
      >
        <span
          style={{
            display: "inline",
            fontStyle: "italic",
            whiteSpace: "pre-wrap",
          }}
        >
          {prompt}
        </span>
        <span
          style={{
            display: "inline",
            fontWeight: "bold",
            paddingBottom: "10px",
          }}
        >
          {completion}
        </span>
      </div>
    </div>
  );
};
