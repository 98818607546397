import styled from "@emotion/styled";
import { writing } from "../utils/writing";

import Me from "../assets/MeSmall.jpeg";

const Homepage = () => {
  return (
    <OuterContainer>
      <Container>
        <About>
          <div style={{ flexDirection: "column" }}>
            <div>
              <h3>About Me</h3>
            </div>
            <div style={{ paddingBottom: "10px" }}>
              Hi I'm Matt. Currently doing backend/AI over at
              <a href="https://hyperwriteai.com" style={{ marginLeft: "5px" }}>
                OthersideAI
              </a>
              , integrating AI into every writing surface of the web.
            </div>
            <div>
              Twitter:{" "}
              <a href="https://twitter.com/badphilosopher">@badphilosopher</a>
            </div>
          </div>
          <div style={{ marginLeft: "40px" }}>
            <img src={Me} alt="Me!" style={{ height: "200px" }} />
          </div>
        </About>
        <Writing>
          <h3>Some things I've written</h3>
          {writing.map(({ title, description, link, location }) => (
            <WritingExample
              key={title}
              title={title}
              description={description}
              link={link}
              location={location}
            />
          ))}
        </Writing>
        <SpacingDiv /> <SpacingDiv />
        <SpacingDiv /> <SpacingDiv />
        <SpacingDiv /> <SpacingDiv />
      </Container>
    </OuterContainer>
  );
};

export default Homepage;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Container = styled.div`
  height: 100vh;
  max-width: 750px;
  display: box;
  padding: 10px 30px 30px 30px;
`;

const About = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: row;
`;

const Writing = styled.div``;

const WritingExample = ({ title, description, link, location }) => {
  return (
    <WritingExampleContainer>
      <WritingExampleTitle>{title}</WritingExampleTitle>
      <WritingExampleDescription>{description}</WritingExampleDescription>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          fontSize: "13px",
          paddingTop: "5px",
        }}
      >
        <WritingExampleLink href={link}>Read More</WritingExampleLink>
        <div style={{ marginLeft: "5px" }}> at {location}</div>
      </div>
    </WritingExampleContainer>
  );
};

const WritingExampleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  //if screen size
  max-width: calc(100% - 25px);
`;

const WritingExampleTitle = styled.div`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const WritingExampleDescription = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
`;

const WritingExampleLink = styled.a`
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

const SpacingDiv = styled.div`
  height: 20px;
`;
