import React from "react";
import ReactDOM from "react-dom/client";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import HttpsRedirect from "react-https-redirect";
import { render } from "react-dom";

import Homepage from "./screens/Homepage";
import Blog from "./screens/Blog";
import Header from "./components/Header2";
import LookingAtFormatting from "./screens/LookingAtFormatting";

console.log("hello world");
const root = document.getElementById("root");
render(
  <HttpsRedirect>
    <BrowserRouter>
      <Header></Header>
      <Routes>
        <Route path="/blog" element={<Blog />} />
        <Route
          path="/prompt-structure-for-directing-inference-draft"
          element={<LookingAtFormatting />}
        />
        <Route path="/" element={<Homepage />} />
      </Routes>
    </BrowserRouter>
  </HttpsRedirect>,
  root
);
