export const writing = [
  {
    title: "1+1=3. Wait, no, 1+1=2. How to have GPT sanity check itself.",
    description:
      "Using J to show having a large language model double check its work improves performance. Also found similar results with Codex.",
    link: "https://medium.com/p/136e846987bf",
    location: "Towards Data Science",
  },

  {
    title: "Introducing AI Dungeon Translate",
    description:
      "Following in the vein of the prior post, this April Fools post showed that you can improve language translation from English => Emoji by having the AI walk thru the meaning of the symbols.",
    link: "https://latitude.io/blog/introducing-ai-dungeon-translate/",
    location: "the Latitude blog",
  },
  {
    title: "How We Accidentally Gave our Bots their Personalities",
    description:
      "One of the really cool things about these large lanugage models is they do much better when they walk their way thru problems. Depending on how they evaluate different steps of the problem, cool personalities emerge.",
    link: "https://latitude.io/blog/how-we-accidentally-gave-our-bots-their-personalities/",
    location: "the Latitude blog",
  },
  {
    title: "Controlling GPT-3 with Logit Bias",
    description:
      "Explaining how Logit Bias works in LLMs to prevent tokens from being generated.",
    link: "https://latitude.io/blog/controlling-gpt-3-with-logit-bias/",
    location: "the Latitude blog",
  },
  {
    title: "Organizing GPT-3 Prompts - GPT_Prompts",
    description:
      "Attempt at throwing up a wiki for tracking how well different prompts work. Currently looks like someone took down wikidot though. Fun results on Word in Context and Adversarial Natural Language Inference showing having GPT-3 walk its way thru problems drastically improves performance on previously at-chance results.",
    link: "http://gptprompts.wikidot.com/",
    location: "Wikidot",
  },

  {
    title:
      "Leveraging Predictive Power to Estimate Intermediary Agenda Setting",
    description:
      "For my thesis I investigated using SARIMA models to predict how news orgs influence other news orgs over time. Addressing a gap in literature where most journalism research was descriptive rather than predictive.",
    link: "https://www.proquest.com/openview/ad30aaf947f5c44f79cc716fd1b48677/1.pdf?pq-origsite=gscholar&cbl=18750&diss=y/",
    location: "ProQuest",
  },

  {
    title: "The Application of Maneuver Toward Psychology",
    description:
      "A paper I'd written when I was in the Army on why we need to understand local languages.",
    link: "https://mca-marines.org/wp-content/uploads/The-Application-of-Maneuver-Toward-Psychology.pdf/",
    location: "Marine Corps Gazette",
  },
];
