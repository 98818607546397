import styled from "@emotion/styled";
import { useState } from "react";

const Header = () => {
  return (
    <Container>
      <InnerContainer>
        <h2>Matt Brockman</h2>
        <Nav>
          <NavButton link="/">Home</NavButton>
          {/* <NavButton link="/blog">Blog</NavButton> */}
        </Nav>
      </InnerContainer>
    </Container>
  );
};

export default Header;

const Container = styled.div`
  width: 100vw;
  padding-bottom: 10px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 750px;
  max-width: calc(100% - 40px);
`;

const Nav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
`;

const NavButton = ({ children, link }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  //box shadow that shows up when hovered, goes white when clicked, and gone when not hovered
  const showBoxShadow = isHovered
    ? !isClicked
      ? "1px 2px 2px rgba(0, 0, 0, 0.2)"
      : "1px 2px 2px rgba(255, 255, 255, 0.2)"
    : "1px 2px 2px rgba(0, 0, 0, 0.2)";

  return (
    <div
      style={{
        width: "100px",
        textAlign: "center",
        cursor: "pointer",
        boxShadow: showBoxShadow,
        borderRadius: "3px",
        marginRight: "10px",
      }}
      onMouseDown={() => setIsClicked(true)}
      onMouseUp={() => setIsClicked(false)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => (window.location.href = link)}
    >
      {children}
    </div>
  );
};
